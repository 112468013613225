<template>
  <div
    class="dropzone dz-clickable"
    :class="[multiple ? 'dropzone-multiple' : 'dropzone-single']"
  >
    <div class="fallback">
      <div class="custom-file">
        <input
          type="file"
          class="custom-file-input"
          id="projectCoverUploads"
          :multiple="multiple"
        />
        <label class="custom-file-label" for="projectCoverUploads"
          >Choose file</label
        >
      </div>
    </div>
    <div
      class="dz-preview dz-preview-single"
      v-if="!multiple"
      :class="previewClasses"
      ref="previewSingle"
    >
      <div class="dz-preview-cover">
        <img class="dz-preview-img" data-dz-thumbnail />
      </div>
    </div>
    <ul
      class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
      :class="previewClasses"
      v-else
    >
      <template v-for="(file, index) in savedFiles">
        <li class="list-group-item px-0" :key="index">
          <div class="row align-items-center">
            <div class="col-auto position-relative">
              <div class="avatar" :class="{ 'download-trigger': downloadable }">
                <img class="avatar-img rounded" :src="file.file" />
              </div>
              <div
                v-if="downloadable"
                class="avatar position-absolute download-hover bg-primary"
              >
                <base-button
                  outline
                  type
                  class="shadow-none text-white"
                  @click="handleDownload(file)"
                >
                  <i class="fas fa-download"></i>
                </base-button>
              </div>
            </div>
            <div class="col ml--3">
              <h4 class="mb-1">{{ file.id }}</h4>
              <p class="small text-muted mb-0">{{ file.created_at }}</p>
            </div>
            <div class="col-auto">
              <button class="btn btn-danger btn-sm" @click="removeFile(file)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <div class="" style="display:none;" ref="previewMultiple" />
    <template v-if="!savedFiles.length && multiple">
      <p class="mt-3 text-xs text-muted">
        {{ $t('argon.inputs.dropzone.no_file') }}
      </p>
    </template>
  </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
  name: 'dropzone-file-upload',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    value: [String, Object, Array],
    url: {
      type: String,
      default: 'http://',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    previewClasses: [String, Object, Array],
    savedFiles: [Array, Boolean],
    downloadable: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      currentFile: null,
      showList: false,
      files: [],
    }
  },
  methods: {
    async initDropzone() {
      let Dropzone = await import('dropzone')

      Dropzone = Dropzone.default || Dropzone
      Dropzone.autoDiscover = false

      const preview = this.$refs.previewMultiple

      const self = this

      const finalOptions = {
        ...this.options,
        url: this.url,
        thumbnailWidth: null,
        thumbnailHeight: null,
        previewsContainer: preview,
        maxFiles: null,
        acceptedFiles: 'image/*,video/*',
      }

      this.dropzone = new Dropzone(this.$el, finalOptions)

      preview.innerHTML = ''

      this.dropzone.on('addedfile', data => {
        this.files.push(data)
        this.$emit('add', this.files)
        preview.innerHTML = ''
      })
    },

    async removeFile(data) {
      const { isConfirmed } = await Swal.fire({
        title: 'Você tem certeza?',
        text: 'Esta ação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar!',
      })

      if (isConfirmed) {
        this.$emit('remove', data)
      }
    },

    handleDownload(file) {
      this.$emit('download', file)
    },
  },
  async mounted() {
    this.initDropzone()
  },

  beforeDestroy() {
    this.files = []
  },
}
</script>

<style lang="scss">
.dz-button {
  margin: 0;
  color: #5e72e4;
  background-color: transparent;
  background-image: none;
  border: 1px solid #5e72e4;
  border-radius: 0.25rem;
  padding: 0.625rem 1.25rem;
}

.dz-message {
  padding: 1rem;
}

.download-hover {
  z-index: -1;

  left: 15px;

  opacity: 0;
  will-change: opacity;
  transition: opacity 0.2s ease-in-out;
}

.download-trigger:hover ~ .download-hover,
.download-hover:hover {
  z-index: 2;
  opacity: 1;
}
</style>
